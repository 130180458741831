import { legislationConstants } from '../_constants';

const INITIAL_STATE = {
  error: null,
  token: null,
  loading: null,
  title: '',
  content: '',
  commentary: null,
  guidancenotes: null,
  questions: [],
  course_units: [],
  author: {
    id: 0,
    name: '',
    email: '',
  },
  parent: {
    id: 0,
    name: '',
    email: '',
  },
  previous: [],
  next: [],
  item: {
    title: '',
    content: '',
    commentary: null,
    guidancenotes: null,
    questions: [],
    course_units: [],
    author: {
      id: 0,
      name: null,
      email: null,
    },
    parent: {
      id: 0,
      name: null,
      email: null,
    },
    previous: [],
    next: [],
  }
}

export function legislations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case legislationConstants.GETALL_REQUEST:
      return {
         ...state,
         loading: true
      };
    case legislationConstants.GETALL_SUCCESS:
      return {
         ...state,
         items: action.legislations.data
      };
    case legislationConstants.GETALL_FAILURE:
      return {
         ...state,
         error: action.error
      };
    default:
      return state
  }
}


export function legislation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case legislationConstants.REGISTER_SUCCESS:
      return {
         ...state,
         item: action.legislation.data
      };
    case legislationConstants.GET_REQUEST:
      return {
         ...state,
         loading: true
      };
    case legislationConstants.GET_SUCCESS:
      return {
         ...state,
         item: action.legislation.data
      };
    case legislationConstants.GET_FAILURE:
      return {
         ...state,
         error: action.error
      };
    case legislationConstants.DELETE_REQUEST:
      return {
         ...state,
         loading: true
      };
    case legislationConstants.DELETE_SUCCESS:
      return {
         ...state,
         loading: true
      };
    case legislationConstants.DELETE_FAILURE:
      return {
         ...state,
         loading: true
      };
    default:
      return state
  }
}


export function break_content(state = {}, action) {
  switch (action.type) {
    case legislationConstants.BREAK_REQUEST:
      return {
        loading: true
      };
    case legislationConstants.BREAK_SUCCESS:
      return {
        items: action.break_content.data.request
      };
    case legislationConstants.BREAK_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}