import React, { Suspense } from 'react';
import { Switch, Router, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import PrivateRouteCMS from '../_components/PrivateRouteCMS';
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import withTracker from '../_components/GoogleAnalytics';
//import AnalyticsListener from "../_components/AnalyticsListener";

import Error404 from '../TaxworldTheme/Home/Error404'


const LoginCMS = Loadable({
    loader: () => import('../TaxworldTheme/CMS/Login'),
    loading: Loader
});

const FrontV3Layout = Loadable({
    loader: () => import('./layout/FrontV3Layout'),
    loading: Loader
});

/*
const LandingLayout = Loadable({
    loader: () => import('./layout/LandingLayout'),
    loading: Loader
});
*/

const HomeLayout = Loadable({
    loader: () => import('./layout/HomeLayout'),
    loading: Loader
});

const CustomerLayout = Loadable({
    loader: () => import('./layout/CustomerLayout'),
    loading: Loader
});

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const ResetPasswordCMS = Loadable({
    loader: () => import('../TaxworldTheme/CMS/ResetPassword'),
    loading: Loader
});

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

    render() {

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                        {alert.message &&
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                        }
                        <Router history={history}>
                            <Switch>
                                <Route exact path="/" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/ezylia" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/why-join" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/membership" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/company" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/publications" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/events" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/help" component={withTracker(FrontV3Layout,{} , this.props.user )}/>

                                <Route exact path="/benefits-checklists" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/benefits-members-area" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/benefits-advice-letters" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/benefits-intuitive-layout" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/benefits-taxcademy" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/benefits-realtime-updating" component={withTracker(FrontV3Layout,{} , this.props.user )}/>

                                <Route exact path="/trustpilot" component={withTracker(FrontV3Layout,{} , this.props.user )}/>

                                <Route exact path="/conditions-of-use" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/privacy-policy" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <Route exact path="/cookies-policy" component={withTracker(FrontV3Layout,{} , this.props.user )}/>
                                <PrivateRoute path="/my-account" component={withTracker(CustomerLayout,{} , this.props.user)} />
                                <Route exact path="/cms/reset-password" component={ResetPasswordCMS} />
                                <Route exact path="/cms/login" component={LoginCMS} />
                                <PrivateRouteCMS path="/cms" component={AdminLayout} />
                                <Route path="/" component={withTracker(HomeLayout,{} , this.props.user)} />
                                <Route path="*" component={withTracker(Error404,{} , this.props.user)} />
                            </Switch>
                        </Router>
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

const mapStateToProps = state => ({
  alert: state.alert,
  user: state.authentication.user,
  header: state.authentication.header
})

const actionCreators = {
    clearAlerts: alertActions.clear,
};

const connectedApp = connect(mapStateToProps, actionCreators)(App);
export { connectedApp as App };
