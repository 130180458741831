import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const letterService = {
  register,
  getAll,
  getById,
  update,
  updateTemplate,
  removeTemplate,
  downloadTemplate,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/letters${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/letters/${id}`, requestOptions).then(handleResponse);
}

function register(letter) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(letter)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/letters`, requestOptions).then(handleResponse);
}

function update(letter) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(letter)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/letters/${letter.id}`, requestOptions).then(handleResponse);
}

function updateTemplate(file, id) {
  let user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`));
  const requestOptions = {
    method: 'POST',
    //headers: authHeader(),
    headers: { 'Authorization': 'Bearer ' + user.token },
    cache: 'no-store',
    body: file
  };

  return fetch(`${process.env.REACT_APP_API_URL}/letters/update_template/${id}`, requestOptions).then(handleResponse);
}

function removeTemplate(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/letters/remove_template/${id}`, requestOptions).then(handleResponse);
}

function downloadTemplate(id, download_file_name) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/letters/download_template/${id}`, requestOptions)
  .then(response => response.blob())
  .then(function(ebookBlob) {
    var objectURL = URL.createObjectURL(ebookBlob);
    let a = document.createElement('a');
    a.href = objectURL;
    a.download = download_file_name;
    a.click();
    return true;
  });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/letters/${id}`, requestOptions).then(handleResponse);
}


