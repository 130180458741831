import React, { Component, } from "react";
import GoogleAnalytics from "react-ga4";

if(process.env.REACT_APP_NODE_ENV === 'production') {
  GoogleAnalytics.initialize("G-L87KFED8NL"); //G-L87KFED8NL
}
let _hsq = window._hsq = window._hsq || [];

const withTracker = (WrappedComponent, options = {}, user = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  const sendPageViewHubspot = location => {
    _hsq.push(['setPath', location]);
    if(user) {
      if(user.email) {
        _hsq.push(["identify",{
          email: user.email
        }]);
      } else {
        if(user.email) {
          _hsq.push(["identify",{
            email: user.username
          }]);
        }
      }
    }
    _hsq.push(['trackPageView']);
  }

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      if(process.env.REACT_APP_NODE_ENV === 'production') {
        trackPage(page);
        sendPageViewHubspot(page);
      }
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        if(process.env.REACT_APP_NODE_ENV === 'production') {
          trackPage(nextPage);
          sendPageViewHubspot(nextPage);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
