import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const manualService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/manuals${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/manuals/${id}`, requestOptions).then(handleResponse);
}

function register(manual) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(manual)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/manuals`, requestOptions).then(handleResponse);
}

function update(manual) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(manual)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/manuals/${manual.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/manuals/${id}`, requestOptions).then(handleResponse);
}


