import { taxemailConstants } from '../_constants';
import { taxemailService } from '../_services';
import { alertActions } from '.';

export const taxemailActions = {
  register,
  getById,
  getAll,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    taxemailService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: taxemailConstants.GET_REQUEST, id } }
  function success(taxemail) { return { type: taxemailConstants.GET_SUCCESS, taxemail } }
  function failure(error) { return { type: taxemailConstants.GET_FAILURE, error } }
}

function register(taxemail) {
  return dispatch => {
    dispatch(request(taxemail));

    taxemailService.register(taxemail)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(taxemail) { return { type: taxemailConstants.REGISTER_REQUEST, taxemail } }
  function success(taxemail) { return { type: taxemailConstants.REGISTER_SUCCESS, taxemail } }
  function failure(error) { return { type: taxemailConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    taxemailService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: taxemailConstants.GETALL_REQUEST } }
  function success(taxemails) { return { type: taxemailConstants.GETALL_SUCCESS, taxemails } }
  function failure(error) { return { type: taxemailConstants.GETALL_FAILURE, error } }
}

function update(taxemail) {
  return dispatch => {
    dispatch(request(taxemail));

    taxemailService.update(taxemail)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(taxemail) { return { type: taxemailConstants.REGISTER_REQUEST, taxemail } }
  function success(taxemail) { return { type: taxemailConstants.REGISTER_SUCCESS, taxemail } }
  function failure(error) { return { type: taxemailConstants.REGISTER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    taxemailService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: taxemailConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: taxemailConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: taxemailConstants.DELETE_FAILURE, id, error } }
}
