import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { manuals, manual } from './manuals.reducer';
import { blogposts, blogpost, subscribe } from './blog.reducer';
import { blogcomments, blogcomment } from './blog_comment.reducer';
import { blogtopics, blogtopic } from './blog_topic.reducer';
import { categories, category, category_sort, category_id } from './category.reducer';
import { checklists, checklist } from './checklist.reducer';
import { questions, question } from './question.reducer';
import { question_categories, question_category } from './question_category.reducer';
import { answers, answer } from './answer.reducer';
import { topics, topic } from './topic.reducer';
import { guidances, guidance } from './guidance.reducer';
import { legislations, legislation, break_content } from './legislation.reducer';
import { appeals, appeal, appeals_import } from './appeals.reducer';
import { briefings, briefing } from './briefings.reducer';
import { ebriefs, ebrief, ebriefs_import } from './ebriefs.reducer';
import { products, product, product_download, product_check_bought, products_bought, spotlight_sort } from './product.reducer';
import { product_categories, product_category } from './product_category.reducer';
import { orders, order } from './order.reducer';
import { users, user, register, activate, user_reset, roles, authors, my_addresses, my_purchases, my_courses, my_books, my_questions, my_answers, my_cart, message } from './users.reducer';
import { addresses, address } from './address.reducer';
import { files, file, file_image } from './files.reducer';
import { carts, cart, remove_products } from './cart.reducer';
import { courses, course, course_enroll } from './course.reducer';
import { course_categories, course_category } from './course_category.reducer';
import { course_modules, course_module, course_modules_sort } from './course_module.reducer';
import { course_units, course_unit } from './course_unit.reducer';
import { course_view, modules_view, module_view, units_view, unit_view } from './course_view.reducer';
import { search_term, search, search_advanced } from './search.reducer';
import { cases, case_data, cases_years, cases_sections, cases_import } from './cases.reducer';
import { events, event } from './event.reducer';
import { letters, letter, letter_template } from './letter.reducer';
import { taxemails, taxemail } from './taxemail.reducer';
import { forms, form } from './forms.reducer';
import { summaries, summary, summaries_years } from './summary.reducer';
import { alert } from './alert.reducer';
import { status } from './status.reducer';
import { defaultreducer } from './default.reducer';
//import { reducer as defaultReducer } from '../store/reducer';


const rootReducer = combineReducers({
  defaultreducer,
  authentication,
  registration,
  users, user, register, activate, user_reset, roles, authors, my_addresses, my_purchases, my_courses, my_books, my_questions, my_answers, my_cart, message,
  addresses, address,
  categories, category, category_sort, category_id,
  manuals, manual,
  checklists, checklist,
  questions, question,
  question_categories, question_category,
  answers, answer,
  blogposts, blogpost, subscribe,
  blogcomments, blogcomment,
  blogtopics, blogtopic,
  topics, topic,
  guidances, guidance,
  legislations, legislation, break_content,
  appeals, appeal, appeals_import,
  briefings, briefing,
  ebriefs, ebrief, ebriefs_import,
  products, product, product_download, product_check_bought, products_bought, spotlight_sort,
  product_categories, product_category,
  orders, order,
  files, file, file_image,
  carts, cart, remove_products,
  courses, course, course_enroll,
  course_categories, course_category, course_modules_sort,
  course_modules, course_module,
  course_units, course_unit,
  course_view, modules_view, module_view, units_view, unit_view,
  search_term, search, search_advanced,
  cases, case_data, cases_years, cases_sections, cases_import,
  events, event,
  letters, letter, letter_template,
  taxemails, taxemail,
  forms, form,
  summaries, summary, summaries_years,
  alert,
  status,
});

export default rootReducer;
