import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const taxemailService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/taxemails${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/taxemails/${id}`, requestOptions).then(handleResponse);
}

function register(taxemail) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(taxemail)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/taxemails`, requestOptions).then(handleResponse);
}

function update(taxemail) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(taxemail)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/taxemails/${taxemail.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/taxemails/${id}`, requestOptions).then(handleResponse);
}


