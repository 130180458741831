import { topicConstants } from '../_constants';
import { topicService } from '../_services';
import { alertActions } from '.';

export const topicActions = {
  register,
  getById,
  getByLetter,
  getAll,
  update,
  delete: _delete,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    topicService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: topicConstants.GET_REQUEST, id } }
  function success(topic) { return { type: topicConstants.GET_SUCCESS, topic } }
  function failure(error) { return { type: topicConstants.GET_FAILURE, error } }
}

function getByLetter(letter) {

  return dispatch => {
    dispatch(request());

    topicService.getByLetter(letter)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response, letter))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request() { return { type: topicConstants.GETALL_REQUEST } }
  function success(topics, letter) { return { type: topicConstants.GETALL_SUCCESS, data: {topics: topics, letter: letter} } }
  function failure(error) { return { type: topicConstants.GETALL_FAILURE, error } }
}

function register(topic) {
  return dispatch => {
    dispatch(request(topic));

    topicService.register(topic)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(topic) { return { type: topicConstants.REGISTER_REQUEST, topic } }
  function success(topic) { return { type: topicConstants.REGISTER_SUCCESS, topic } }
  function failure(error) { return { type: topicConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    topicService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: topicConstants.GETALL_REQUEST } }
  function success(topics) { return { type: topicConstants.GETALL_SUCCESS, data: {topics: topics, letter: ''} } }
  function failure(error) { return { type: topicConstants.GETALL_FAILURE, error } }
}

function update(topic) {
  return dispatch => {
    dispatch(request(topic));

    topicService.update(topic)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(topic) { return { type: topicConstants.REGISTER_REQUEST, topic } }
  function success(topic) { return { type: topicConstants.REGISTER_SUCCESS, topic } }
  function failure(error) { return { type: topicConstants.REGISTER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    topicService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response.id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: topicConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: topicConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: topicConstants.DELETE_FAILURE, id, error } }
}