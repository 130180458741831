import { legislationConstants } from '../_constants';
import { legislationService } from '../_services';
import { alertActions } from '.';

export const legislationActions = {
  register,
  getById,
  getAll,
  update,
  delete: _delete,
  breakContent,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    legislationService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: legislationConstants.GET_REQUEST, id } }
  function success(legislation) { return { type: legislationConstants.GET_SUCCESS, legislation } }
  function failure(error) { return { type: legislationConstants.GET_FAILURE, error } }
}

function register(legislation) {
  return dispatch => {
    dispatch(request(legislation));

    legislationService.register(legislation)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Legislation registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(legislation) { return { type: legislationConstants.REGISTER_REQUEST, legislation } }
  function success(legislation) { return { type: legislationConstants.REGISTER_SUCCESS, legislation } }
  function failure(error) { return { type: legislationConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    legislationService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: legislationConstants.GETALL_REQUEST } }
  function success(legislations) { return { type: legislationConstants.GETALL_SUCCESS, legislations } }
  function failure(error) { return { type: legislationConstants.GETALL_FAILURE, error } }
}

function update(legislation) {
  return dispatch => {
    dispatch(request(legislation));

    legislationService.update(legislation)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Legislation updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(legislation) { return { type: legislationConstants.REGISTER_REQUEST, legislation } }
  function success(legislation) { return { type: legislationConstants.REGISTER_SUCCESS, legislation } }
  function failure(error) { return { type: legislationConstants.REGISTER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    legislationService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response.id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: legislationConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: legislationConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: legislationConstants.DELETE_FAILURE, id, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function breakContent(id, titles, category_id) {
  return dispatch => {
    dispatch(request(id));

    legislationService.breakContent(id, titles, category_id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: legislationConstants.BREAK_REQUEST, id } }
  function success(break_content) { return { type: legislationConstants.BREAK_SUCCESS, break_content } }
  function failure(id, error) { return { type: legislationConstants.BREAK_FAILURE, id, error } }
}