import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { statusActions } from './';
import { history } from '../_helpers';
import axios from 'axios';

export const userActions = {
  isValidToken,
  loginCMS,
  login,
  logoutCMS,
  logout,
  resetRequest,
  resetVerify,
  resetChange,
  register,
  activate,
  getAll,
  getById,
  getProfile,
  getRoles,
  getAuthors,
  update,
  delete: _delete,
  validateToken,
  unauthorisedCMS,
  contactForm,
  interestedUnlimited,
  myAddresses,
  myPurchases,
  myCourses,
  myBooks,
  myQuestions,
  myAnswers,
  myCart,
};

function isValidToken(token) {

  return userService.isValidToken(token)
    .then(
      auth => {
        //console.log(auth)
        return auth
        //if(auth === 401) {
        //  history.push('/');
        //}
        //history.push('/my-account');
      }
    );
}

function loginCMS(username, password) {

  return dispatch => {
    dispatch(request({ username }));

    userService.loginCMS(username, password)
      .then(
        user => {
          dispatch(success(user));
          history.push('/cms/desktop');
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

export function validateToken(token) {
  return dispatch => {
    if (token) {
      axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.defaults.headers.common['Accept'] = 'application/json'; // 'Accept': 'application/json',
      axios.defaults.headers.common['Content-Type'] = 'application/json'; // 'Content-Type': 'application/json'
      axios.get('/validate-token')
        .then(resp => {
          dispatch({ type: 'TOKEN_VALIDATED', payload: resp.data.valid })
        })
        .catch(e => dispatch({ type: 'TOKEN_VALIDATED', payload: false }))
    } else {
      dispatch({ type: 'TOKEN_VALIDATED', payload: false })
    }
  }
}

function login(username, password, redirect) {

  return dispatch => {
    dispatch(request({ username }));

    userService.login(username, password)
      .then(
        user => {
          dispatch(success(user));
          if(redirect !== 'no-redirect') {
            history.push('/welcome-back');
          }
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logoutCMS() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function unauthorisedCMS() {
  userService.logout();
  history.push('/cms/login');
  return { type: 'TOKEN_UNAUTHORISED' };
}

function getProfile(id) {

  return dispatch => {
    dispatch(request({ id }));

    userService.getProfile(id)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(id) { return { type: userConstants.GET_PROFILE_REQUEST, id } }
  function success(user) { return { type: userConstants.GET_PROFILE_SUCCESS, user } }
  function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error } }
}

function getRoles() {

  return dispatch => {
    dispatch(request());

    userService.getRoles()
      .then(
        roles => {
          dispatch(success(roles));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.ROLES_GETALL_REQUEST, roles: {} } }
  function success(roles) { return { type: userConstants.ROLES_GETALL_SUCCESS, roles } }
  function failure(error) { return { type: userConstants.ROLES_GETALL_FAILURE, error } }
}

function getAuthors() {

  return dispatch => {
    dispatch(request());

    userService.getAuthors()
      .then(
        authors => {
          dispatch(success(authors));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.AUTHORS_GETALL_REQUEST, authors: {} } }
  function success(authors) { return { type: userConstants.AUTHORS_GETALL_SUCCESS, authors } }
  function failure(error) { return { type: userConstants.AUTHORS_GETALL_FAILURE, error } }
}

function register(user, redirectSuccess=true) {
  return dispatch => {
    dispatch(request(user));

    userService.register(user)
      .then(
        register => {
          dispatch(success(register));
          if( redirectSuccess === true ) {
            history.push('/sign-up/success');
          }
          dispatch(statusActions.success('Registration successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
  function success(register) { return { type: userConstants.REGISTER_SUCCESS, register } }
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function activate(token) {
  return dispatch => {
    dispatch(request(token));

    userService.activate(token)
      .then(
        activate => {
          dispatch(success(activate));
          dispatch(statusActions.success('Activation successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request(token) { return { type: userConstants.ACTIVATE_REQUEST, token } }
  function success(activate) { return { type: userConstants.ACTIVATE_SUCCESS, activate } }
  function failure(error) { return { type: userConstants.ACTIVATE_FAILURE, error } }
}

function resetRequest(username) {
  return dispatch => {
    dispatch(request(username));

    userService.resetRequest(username)
      .then(
        reset => {
          dispatch(success(reset));
          dispatch(statusActions.success('Reset request successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request(user) { return { type: userConstants.RESET_REQUEST_REQUEST, user } }
  function success(reset) { return { type: userConstants.RESET_REQUEST_SUCCESS, reset } }
  function failure(error) { return { type: userConstants.RESET_REQUEST_FAILURE, error } }
}

function resetVerify(token) {
  return dispatch => {
    dispatch(request(token));

    userService.resetVerify(token)
      .then(
        reset => {
          dispatch(success(reset));
          dispatch(statusActions.success('Reset verify successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request(token) { return { type: userConstants.RESET_VERIFY_REQUEST, token } }
  function success(reset) { return { type: userConstants.RESET_VERIFY_SUCCESS, reset } }
  function failure(error) { return { type: userConstants.RESET_VERIFY_FAILURE, error } }
}

function resetChange(user) {
  return dispatch => {
    dispatch(request(user));

    userService.resetChange(user)
      .then(
        user => {
          dispatch(success(user));
          dispatch(statusActions.success('Reset password successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request(user) { return { type: userConstants.RESET_CHANGE_REQUEST, user } }
  function success(reset) { return { type: userConstants.RESET_CHANGE_SUCCESS, reset } }
  function failure(error) { return { type: userConstants.RESET_CHANGE_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    userService.getAll(api_params)
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: userConstants.GETALL_REQUEST } }
  function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
  function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    userService.getById(id)
      .then(
        user => {
          dispatch(success(user))
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request(id) { return { type: userConstants.GET_PROFILE_REQUEST, id } }
  function success(user) { return { type: userConstants.GET_PROFILE_SUCCESS, user } }
  function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error } }
}

function update(user) {
  return dispatch => {
    dispatch(request(user));

    userService.update(user)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('User updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(user) { return { type: userConstants.UPDATE_PROFILE_REQUEST, user } }
  function success(user) { return { type: userConstants.UPDATE_PROFILE_SUCCESS, user } }
  function failure(error) { return { type: userConstants.UPDATE_PROFILE_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    userService.delete(id)
      .then(
        user => {
          dispatch(success(id));
        },
        error => {
          dispatch(failure(id, error.toString()));
        }
      );
  };

  function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function contactForm(message) {
  return dispatch => {
    dispatch(request());

    userService.contactForm(message)
      .then(
        message => {
          dispatch(success(message));
          dispatch(statusActions.success('Message successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.MESSAGE_REQUEST } }
  function success(message) { return { type: userConstants.MESSAGE_SUCCESS, message } }
  function failure(error) { return { type: userConstants.MESSAGE_FAILURE, error } }
}

function interestedUnlimited(message) {
  return dispatch => {
    dispatch(request());

    userService.interestedUnlimited(message)
      .then(
        message => {
          dispatch(success(message));
          dispatch(statusActions.success('Message successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.MESSAGE_REQUEST } }
  function success(message) { return { type: userConstants.MESSAGE_SUCCESS, message } }
  function failure(error) { return { type: userConstants.MESSAGE_FAILURE, error } }
}

function myAddresses() {
  return dispatch => {
    dispatch(request());

    userService.myAddresses()
      .then(
        my_addresses => {
          dispatch(success(my_addresses));
          dispatch(statusActions.success('My addresses successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.ADDRESSES_GETALL_REQUEST } }
  function success(my_addresses) { return { type: userConstants.ADDRESSES_GETALL_SUCCESS, my_addresses } }
  function failure(error) { return { type: userConstants.ADDRESSES_GETALL_FAILURE, error } }
}

function myPurchases() {
  return dispatch => {
    dispatch(request());

    userService.myPurchases()
      .then(
        my_purchases => {
          dispatch(success(my_purchases));
          dispatch(statusActions.success('My orders successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.PURCHASES_GETALL_REQUEST } }
  function success(my_purchases) { return { type: userConstants.PURCHASES_GETALL_SUCCESS, my_purchases } }
  function failure(error) { return { type: userConstants.PURCHASES_GETALL_FAILURE, error } }
}

function myCourses() {
  return dispatch => {
    dispatch(request());

    userService.myCourses()
      .then(
        my_courses => {
          dispatch(success(my_courses));
          dispatch(statusActions.success('My courses successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.COURSES_GETALL_REQUEST } }
  function success(my_courses) { return { type: userConstants.COURSES_GETALL_SUCCESS, my_courses } }
  function failure(error) { return { type: userConstants.COURSES_GETALL_FAILURE, error } }
}

function myBooks() {
  return dispatch => {
    dispatch(request());

    userService.myBooks()
      .then(
        my_books => {
          dispatch(success(my_books));
          dispatch(statusActions.success('My books successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.BOOKS_GETALL_REQUEST } }
  function success(my_books) { return { type: userConstants.BOOKS_GETALL_SUCCESS, my_books } }
  function failure(error) { return { type: userConstants.BOOKS_GETALL_FAILURE, error } }
}

function myQuestions(page) {
  return dispatch => {
    dispatch(request());

    userService.myQuestions(page)
      .then(
        my_questions => {
          dispatch(success(my_questions));
          dispatch(statusActions.success('My questions successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.QUESTIONS_GETALL_REQUEST } }
  function success(my_questions) { return { type: userConstants.QUESTIONS_GETALL_SUCCESS, my_questions } }
  function failure(error) { return { type: userConstants.QUESTIONS_GETALL_FAILURE, error } }
}

function myAnswers(page) {
  return dispatch => {
    dispatch(request());

    userService.myAnswers(page)
      .then(
        my_answers => {
          dispatch(success(my_answers));
          dispatch(statusActions.success('My answers successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.ANSWERS_GETALL_REQUEST } }
  function success(my_answers) { return { type: userConstants.ANSWERS_GETALL_SUCCESS, my_answers } }
  function failure(error) { return { type: userConstants.ANSWERS_GETALL_FAILURE, error } }
}

function myCart() {
  return dispatch => {
    dispatch(request());

    userService.myCart()
      .then(
        my_cart => {
          dispatch(success(my_cart));
          dispatch(statusActions.success('My cart successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.SHOPPING_CART_GET_REQUEST } }
  function success(my_cart) { return { type: userConstants.SHOPPING_CART_GET_SUCCESS, my_cart } }
  function failure(error) { return { type: userConstants.SHOPPING_CART_GET_FAILURE, error } }
}
