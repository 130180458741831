export const topicConstants = {
  REGISTER_REQUEST: 'TOPIC_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'TOPIC_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'TOPIC_REGISTER_FAILURE',

  GET_REQUEST: 'TOPIC_GET_REQUEST',
  GET_SUCCESS: 'TOPIC_GET_SUCCESS',
  GET_FAILURE: 'TOPIC_GET_FAILURE',

  GETALL_REQUEST: 'TOPIC_GETALL_REQUEST',
  GETALL_SUCCESS: 'TOPIC_GETALL_SUCCESS',
  GETALL_FAILURE: 'TOPIC_GETALL_FAILURE',

  DELETE_REQUEST: 'TOPIC_DELETE_REQUEST',
  DELETE_SUCCESS: 'TOPIC_DELETE_SUCCESS',
  DELETE_FAILURE: 'TOPIC_DELETE_FAILURE',

  TOPIC_ACTIVE_NODE: 'TOPIC_ACTIVE_NODE',

  UPDATE_TREE_OPTIONS: 'UPDATE_TREE_OPTIONS',
};