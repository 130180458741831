//import { userService } from '../_services'
//import { userActions } from '../_actions'
//import { history } from '../_helpers';

export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`));

  //console.log(user.headers)

  if(user !== null) {

    /*
    const status = Promise.resolve(userService.isValidToken(user.token));

    status.then(function(code) {
      if(parseInt(code) === 401) {
        //console.log(code)
        userActions.logoutCMS();
        history.push("/cms/login");
      }
    });

    //setTimeout(function() {
    //  console.log("wait");
    //}, 10000);
    */

    return user.headers;

  } else {
    return  {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }

}
