import { categoryConstants } from '../_constants';
import { categoryService } from '../_services';
import { statusActions } from '.';

export const categoryActions = {
  register,
  getId,
  getById,
  getByParentId,
  getAll,
  getPlainList,
  update,
  updateSort,
  updateSortNested,
  delete: _delete
};

function getId(id) {

  return dispatch => {
    dispatch(request({ id }));

    categoryService.getId(id)
      .then(
        category => {
          dispatch(success(category.id))
        },
        error => {
          dispatch(failure(error));
          dispatch(statusActions.error(error));
        }
      );
  };

  function request(id) { return { type: categoryConstants.GET_ID_REQUEST, id } }
  function success(category) { return { type: categoryConstants.GET_ID_SUCCESS, category } }
  function failure(error) { return { type: categoryConstants.GET_ID_FAILURE, error } }
}

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    categoryService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(statusActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: categoryConstants.GET_REQUEST, id } }
  function success(category) { return { type: categoryConstants.GET_SUCCESS, category } }
  function failure(error) { return { type: categoryConstants.GET_FAILURE, error } }
}

function getByParentId(id) {

  return dispatch => {
    dispatch(request({ id }));

    categoryService.getByParentId(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(statusActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: categoryConstants.GET_REQUEST, id } }
  function success(category) { return { type: categoryConstants.GET_SUCCESS, category } }
  function failure(error) { return { type: categoryConstants.GET_FAILURE, error } }
}

function register(category) {
  return dispatch => {
    dispatch(request(category));

    categoryService.register(category)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(statusActions.success('Category registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(statusActions.error(response));
          }
        }
      );
  };

  function request(category) { return { type: categoryConstants.REGISTER_REQUEST, category } }
  function success(category) { return { type: categoryConstants.REGISTER_SUCCESS, category } }
  function failure(error) { return { type: categoryConstants.REGISTER_FAILURE, error } }
}

function update(category) {
  return dispatch => {
    dispatch(request(category));

    categoryService.update(category)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(statusActions.success('Category updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(statusActions.error(response));
          }
        }
      );
  };

  function request(category) { return { type: categoryConstants.REGISTER_REQUEST, category } }
  function success(category) { return { type: categoryConstants.REGISTER_SUCCESS, category } }
  function failure(error) { return { type: categoryConstants.REGISTER_FAILURE, error } }
}

function updateSort(category, type) {
  return dispatch => {
    dispatch(request(category));

    categoryService.updateSort(category, type)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(statusActions.success('Updated sort successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(statusActions.error(response));
          }
        }
      );
  };

  function request(sort) { return { type: categoryConstants.SORT_REQUEST, sort } }
  function success(sort) { return { type: categoryConstants.SORT_SUCCESS, sort } }
  function failure(error) { return { type: categoryConstants.SORT_FAILURE, error } }
}

function updateSortNested(category, type) {
  return dispatch => {
    dispatch(request(category));

    categoryService.updateSortNested(category, type)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(statusActions.success('Updated sort successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(statusActions.error(response));
          }
        }
      );
  };

  function request(sort) { return { type: categoryConstants.SORT_REQUEST, sort } }
  function success(sort) { return { type: categoryConstants.SORT_SUCCESS, sort } }
  function failure(error) { return { type: categoryConstants.SORT_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    categoryService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: categoryConstants.GETALL_REQUEST } }
  function success(categories) { return { type: categoryConstants.GETALL_SUCCESS, categories } }
  function failure(error) { return { type: categoryConstants.GETALL_FAILURE, error } }
}

function getPlainList(api_params) {
  return dispatch => {
    dispatch(request());
    
    categoryService.getPlainList(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: categoryConstants.GETALL_REQUEST } }
  function success(categories) { return { type: categoryConstants.GETALL_SUCCESS, categories } }
  function failure(error) { return { type: categoryConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    categoryService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: categoryConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: categoryConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: categoryConstants.DELETE_FAILURE, id, error } }
}
