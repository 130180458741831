import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const topicService = {
  register,
  getAll,
  getById,
  getByLetter,
  update,
  delete: _delete,
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topic${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topic/${id}`, requestOptions).then(handleResponse);
}

function getByLetter(letter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topics/${letter}`, requestOptions).then(handleResponse);
}

function register(topic) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(topic)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topic`, requestOptions).then(handleResponse);
}

function update(topic) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(topic)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topic/${topic.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topic/${id}`, requestOptions).then(handleResponse);
}