import { manualConstants } from '../_constants';
import { manualService } from '../_services';
import { alertActions } from '.';

export const manualActions = {
  register,
  getById,
  getAll,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    manualService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: manualConstants.GET_REQUEST, id } }
  function success(manual) { return { type: manualConstants.GET_SUCCESS, manual } }
  function failure(error) { return { type: manualConstants.GET_FAILURE, error } }
}

function register(manual) {
  return dispatch => {
    dispatch(request(manual));

    manualService.register(manual)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(manual) { return { type: manualConstants.REGISTER_REQUEST, manual } }
  function success(manual) { return { type: manualConstants.REGISTER_SUCCESS, manual } }
  function failure(error) { return { type: manualConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    manualService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: manualConstants.GETALL_REQUEST } }
  function success(manuals) { return { type: manualConstants.GETALL_SUCCESS, manuals } }
  function failure(error) { return { type: manualConstants.GETALL_FAILURE, error } }
}

function update(manual) {
  return dispatch => {
    dispatch(request(manual));

    manualService.update(manual)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(manual) { return { type: manualConstants.REGISTER_REQUEST, manual } }
  function success(manual) { return { type: manualConstants.REGISTER_SUCCESS, manual } }
  function failure(error) { return { type: manualConstants.REGISTER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    manualService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: manualConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: manualConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: manualConstants.DELETE_FAILURE, id, error } }
}
