import { briefingsConstants } from '../_constants';
import { briefingsService } from '../_services';
import { alertActions } from '.';

export const briefingsActions = {
  register,
  getById,
  getByYear,
  getAll,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    briefingsService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: briefingsConstants.GET_REQUEST, id } }
  function success(briefing) { return { type: briefingsConstants.GET_SUCCESS, briefing } }
  function failure(error) { return { type: briefingsConstants.GET_FAILURE, error } }
}

function getByYear(year) {

  return dispatch => {
    //dispatch(request({ id }));

    briefingsService.getByYear(year)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response, year))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  //function request(briefing) { return { type: briefingsConstants.GETALL_REQUEST, briefing } }
  function success(briefings, year) { return { type: briefingsConstants.GETALL_SUCCESS, briefings: { briefings: briefings, year: year } } }
  function failure(error) { return { type: briefingsConstants.GETALL_FAILURE, error } }
}

function register(briefing) {
  return dispatch => {
    dispatch(request(briefing));

    briefingsService.register(briefing)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(briefing) { return { type: briefingsConstants.REGISTER_REQUEST, briefing } }
  function success(briefing) { return { type: briefingsConstants.REGISTER_SUCCESS, briefing } }
  function failure(error) { return { type: briefingsConstants.REGISTER_FAILURE, error } }
}

function update(briefing) {
  return dispatch => {
    dispatch(request(briefing));

    briefingsService.update(briefing)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(briefing) { return { type: briefingsConstants.REGISTER_REQUEST, briefing } }
  function success(briefing) { return { type: briefingsConstants.REGISTER_SUCCESS, briefing } }
  function failure(error) { return { type: briefingsConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    briefingsService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: briefingsConstants.GETALL_REQUEST } }
  function success(briefings) { return { type: briefingsConstants.GETALL_SUCCESS, briefings: { briefings: briefings, year: '' } } }
  function failure(error) { return { type: briefingsConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    briefingsService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: briefingsConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: briefingsConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: briefingsConstants.DELETE_FAILURE, id, error } }
}
