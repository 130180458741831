import { checklistConstants } from '../_constants';
import { checklistService } from '../_services';
import { alertActions } from '.';

export const checklistActions = {
  register,
  getById,
  getAll,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    checklistService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: checklistConstants.GET_REQUEST, id } }
  function success(checklist) { return { type: checklistConstants.GET_SUCCESS, checklist } }
  function failure(error) { return { type: checklistConstants.GET_FAILURE, error } }
}

function register(checklist) {
  return dispatch => {
    dispatch(request(checklist));

    checklistService.register(checklist)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(checklist) { return { type: checklistConstants.REGISTER_REQUEST, checklist } }
  function success(checklist) { return { type: checklistConstants.REGISTER_SUCCESS, checklist } }
  function failure(error) { return { type: checklistConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    checklistService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: checklistConstants.GETALL_REQUEST } }
  function success(checklists) { return { type: checklistConstants.GETALL_SUCCESS, checklists } }
  function failure(error) { return { type: checklistConstants.GETALL_FAILURE, error } }
}

function update(checklist) {
  return dispatch => {
    dispatch(request(checklist));

    checklistService.update(checklist)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(checklist) { return { type: checklistConstants.REGISTER_REQUEST, checklist } }
  function success(checklist) { return { type: checklistConstants.REGISTER_SUCCESS, checklist } }
  function failure(error) { return { type: checklistConstants.REGISTER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    checklistService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: checklistConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: checklistConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: checklistConstants.DELETE_FAILURE, id, error } }
}
