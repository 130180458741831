import React from 'react';
import {Col, Row, Tabs, Tab} from 'react-bootstrap';
import { connect } from 'react-redux';
import Aux from "../../hoc/_Aux";
import { Link } from "react-router-dom";


class Error404 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    }

    window.scrollTo(0, 0)
  }


    render() {


      return (
        <Aux>
          <Row className="homeContent">
            <Col sm={12} md={12} lg={12} className="homeLeft mb-3">

              <Tabs activeKey="welcomeback" className="innerSection" onSelect="welcomeback">
                <Tab eventKey="welcomeback" title="Error 404 content not found">

                  <div className="breadcrumb_slug"><Link to={`/`}><i className="feather icon-home"/></Link> » <span className="last">404</span></div>

                  <div className="bg-white px-3 p-3 text-center welcome_back_background">

                      <div className="welcome_back">Error 404</div>
                      <p className="lead mt-5 welcome_back_subitle">Sorry, we can’t find the content you were looking for.</p>

                      <Link to="/" className="btn btn-warning welcome_back_cta">Back to Taxworld.ie home</Link>

                  </div>

                </Tab>
              </Tabs>

            </Col>
            
          </Row>
          <br />
          <br />
        </Aux>
      );
    }
}

const mapStateToProps = (state, ownProps) => ({
  //
})

const actionCreators = {
  //
};

export default connect(mapStateToProps, actionCreators)(Error404);