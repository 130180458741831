import { history } from './';

export function handleResponsePublic(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      if (response.status === 429) {
        history.push('/logout');

      } else if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        //location.reload(true);
        // history.push('/logout');

      } else if (response.status === 404 || response.status === 500) {
        history.push('/404');
      } else if (response.status === 403) {
        return data;
      }

      //const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;
      //console.log('ERROR', error);
      return Promise.reject(error);
    }

    return data;
  }).catch((err) => {
    const error = err || JSON.parse(response.statusText);
    return error;
  });
}
