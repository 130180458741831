import { orderConstants } from '../_constants';

export function orders(state = {}, action) {
  switch (action.type) {
    case orderConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case orderConstants.GETALL_SUCCESS:
      return {
        items: action.orders.data
      };
    case orderConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case orderConstants.DELETE_REQUEST:
      // add 'deleting:true' property to order being deleted
      return {
        ...state,
        items: state.items.map(order =>
          order.id === action.id
            ? { ...order, deleting: true }
            : order
        )
      };
    case orderConstants.DELETE_SUCCESS:
      // remove deleted order from state
      return {
        items: state.items.filter(order => order.id !== action.id)
      };
    case orderConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to order
      return {
        ...state,
        items: state.items.map(order => {
          if (order.id === action.id) {
            // make copy of order without 'deleting:true' property
            const { deleting, ...orderCopy } = order;
            // return copy of order with 'deleteError:[error]' property
            return { ...orderCopy, deleteError: action.error };
          }

          return order;
        })
      };
    default:
      return state
  }
}


export function order(state = {}, action) {
  switch (action.type) {
    case orderConstants.REGISTER_SUCCESS:
      return {
        item: action.order.data
      };
    case orderConstants.GET_REQUEST:
      return {
        loading: true
      };
    case orderConstants.GET_SUCCESS:
      return {
        item: action.order.data
      };
    case orderConstants.GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
