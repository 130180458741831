import { blog_commentConstants } from '../_constants';
import { blog_commentService } from '../_services';
import { alertActions } from '.';

export const blog_commentActions = {
  register,
  getById,
  getAll,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    blog_commentService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: blog_commentConstants.GET_REQUEST, id } }
  function success(blogcomment) { return { type: blog_commentConstants.GET_SUCCESS, blogcomment } }
  function failure(error) { return { type: blog_commentConstants.GET_FAILURE, error } }
}

function register(blogcomment) {
  return dispatch => {
    dispatch(request(blogcomment));

    blog_commentService.register(blogcomment)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(blogcomment) { return { type: blog_commentConstants.REGISTER_REQUEST, blogcomment } }
  function success(blogcomment) { return { type: blog_commentConstants.REGISTER_SUCCESS, blogcomment } }
  function failure(error) { return { type: blog_commentConstants.REGISTER_FAILURE, error } }
}

function update(blogcomment) {
  return dispatch => {
    dispatch(request(blogcomment));

    blog_commentService.update(blogcomment)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Comment updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(blogcomment) { return { type: blog_commentConstants.REGISTER_REQUEST, blogcomment } }
  function success(blogcomment) { return { type: blog_commentConstants.REGISTER_SUCCESS, blogcomment } }
  function failure(error) { return { type: blog_commentConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    blog_commentService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: blog_commentConstants.GETALL_REQUEST } }
  function success(blogcomments) { return { type: blog_commentConstants.GETALL_SUCCESS, blogcomments } }
  function failure(error) { return { type: blog_commentConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    blog_commentService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: blog_commentConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: blog_commentConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: blog_commentConstants.DELETE_FAILURE, id, error } }
}
