import { courseConstants } from '../_constants';
import { courseService } from '../_services';
import { alertActions } from '.';

export const courseActions = {
  register,
  getById,
  getAll,
  update,
  enroll,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    courseService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: courseConstants.GET_REQUEST, id } }
  function success(course) { return { type: courseConstants.GET_SUCCESS, course } }
  function failure(error) { return { type: courseConstants.GET_FAILURE, error } }
}

function register(course) {
  return dispatch => {
    dispatch(request(course));

    courseService.register(course)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Course registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(course) { return { type: courseConstants.REGISTER_REQUEST, course } }
  function success(course) { return { type: courseConstants.REGISTER_SUCCESS, course } }
  function failure(error) { return { type: courseConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    courseService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: courseConstants.GETALL_REQUEST } }
  function success(courses) { return { type: courseConstants.GETALL_SUCCESS, courses } }
  function failure(error) { return { type: courseConstants.GETALL_FAILURE, error } }
}

function update(course) {
  return dispatch => {
    dispatch(request(course));

    courseService.update(course)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Course updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(course) { return { type: courseConstants.REGISTER_REQUEST, course } }
  function success(course) { return { type: courseConstants.REGISTER_SUCCESS, course } }
  function failure(error) { return { type: courseConstants.REGISTER_FAILURE, error } }
}

function enroll(slug) {
  return dispatch => {
    dispatch(request());

    courseService.enroll(slug)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Enroll successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request() { return { type: courseConstants.ENROLL_REQUEST } }
  function success(course_enroll) { return { type: courseConstants.ENROLL_SUCCESS, course_enroll } }
  function failure(error) { return { type: courseConstants.ENROLL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    courseService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: courseConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: courseConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: courseConstants.DELETE_FAILURE, id, error } }
}
