import { blogConstants } from '../_constants';
import { blogService } from '../_services';
import { alertActions } from '.';

export const blogActions = {
  register,
  getById,
  getByYear,
  getAll,
  update,
  delete: _delete,
  subscribeEmail,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    blogService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: blogConstants.GET_REQUEST, id } }
  function success(blogpost) { return { type: blogConstants.GET_SUCCESS, blogpost } }
  function failure(error) { return { type: blogConstants.GET_FAILURE, error } }
}

function getByYear(year) {

  return dispatch => {
    //dispatch(request({ id }));

    blogService.getByYear(year)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response, year))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  //function request(appeal) { return { type: blogConstants.GETALL_REQUEST, appeal } }
  function success(blogposts, year) { return { type: blogConstants.GETALL_SUCCESS, blogposts: { blogposts: blogposts, year: year } } }
  function failure(error) { return { type: blogConstants.GETALL_FAILURE, error } }
}

function register(blogpost) {
  return dispatch => {
    dispatch(request(blogpost));

    blogService.register(blogpost)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(blogpost) { return { type: blogConstants.REGISTER_REQUEST, blogpost } }
  function success(blogpost) { return { type: blogConstants.REGISTER_SUCCESS, blogpost } }
  function failure(error) { return { type: blogConstants.REGISTER_FAILURE, error } }
}

function update(blogpost) {
  return dispatch => {
    dispatch(request(blogpost));

    blogService.update(blogpost)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Blogpost update successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(blogpost) { return { type: blogConstants.REGISTER_REQUEST, blogpost } }
  function success(blogpost) { return { type: blogConstants.REGISTER_SUCCESS, blogpost } }
  function failure(error) { return { type: blogConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());
    
    blogService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: blogConstants.GETALL_REQUEST } }
  function success(blogposts) { return { type: blogConstants.GETALL_SUCCESS, blogposts: { blogposts: blogposts, year: '' } } }
  function failure(error) { return { type: blogConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    blogService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: blogConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: blogConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: blogConstants.DELETE_FAILURE, id, error } }
}

function subscribeEmail(subscriber) {
  return dispatch => {
    dispatch(request());

    blogService.subscribeEmail(subscriber)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Subscribe successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));          }
        }
      );
  };

  function request() { return { type: blogConstants.SUBSCRIBE_REQUEST,  } }
  function success(subscribe) { return { type: blogConstants.SUBSCRIBE_SUCCESS, subscribe } }
  function failure(error) { return { type: blogConstants.SUBSCRIBE_FAILURE, error } }
}
