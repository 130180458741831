import { topicConstants } from '../_constants';


export function topics(state = {}, action) {
  switch (action.type) {
    case topicConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case topicConstants.GETALL_SUCCESS:
      return {
        items: action.data.topics.data,
        letter: action.data.letter
      };
    case topicConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function topic(state = {}, action) {
  switch (action.type) {
    case topicConstants.REGISTER_SUCCESS:
      return {
        item: action.topic.data
      };
    case topicConstants.GET_REQUEST:
      return {
        loading: true
      };
    case topicConstants.GET_SUCCESS:
      return {
        item: action.topic.data
      };
    case topicConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case topicConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case topicConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case topicConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}