import { casesConstants } from '../_constants';

export function cases(state = {}, action) {
  switch (action.type) {
    case casesConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case casesConstants.GETALL_SUCCESS:
      return {
        items: action.cases.data
      };
    case casesConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function case_data(state = {}, action) {
  switch (action.type) {
    case casesConstants.REGISTER_SUCCESS:
      return {
        item: action.case_data.data
      };
    case casesConstants.GET_REQUEST:
      return {
        loading: true
      };
    case casesConstants.GET_SUCCESS:
      return {
        item: action.case_data.data
      };
    case casesConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case casesConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case casesConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case casesConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function cases_years(state = {}, action) {
  switch (action.type) {
    case casesConstants.GETALL_YEARS_REQUEST:
      return {
        loading: true
      };
    case casesConstants.GETALL_YEARS_SUCCESS:
      return {
        items: action.cases_years.data
      };
    case casesConstants.GETALL_YEARS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function cases_sections(state = {}, action) {
  switch (action.type) {
    case casesConstants.GETALL_SECTIONS_REQUEST:
      return {
        loading: true
      };
    case casesConstants.GETALL_SECTIONS_SUCCESS:
      return {
        items: action.cases_sections
      };
    case casesConstants.GETALL_SECTIONS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function cases_import(state = {}, action) {
  switch (action.type) {
    case casesConstants.IMPORT_REQUEST:
      return {
        loading: true
      };
    case casesConstants.IMPORT_SUCCESS:
      return {
        items: action.cases_import
      };
    case casesConstants.IMPORT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
